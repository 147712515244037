<template>
  <div>
    <b-row>
      <b-col lg="12" sm="12" class="mb-2" v-if="has_data_breach">
        <b-alert v-height-fade show dismissible fade class="mb-0" variant="warning">
          <div class="alert-body">
            <span>{{ $t("Pages.Accounting.Title.dear_payment_not_completed") }}</span>
            <b-button href="https://master.tube/verification/" size="sm" target="_blank" variant="info" class="mx-2">{{$t("Pages.Accounting.Title.complet_info")}}</b-button>
          </div>
        </b-alert>
      </b-col>
      <b-col lg="12" sm="12">
        <b-overlay :show="loadingTable" spinner-variant="primary" variant="transparent" spinner-type="grow" spinner-small
          rounded="sm">
          <b-card no-body class="card-statistics">
            <b-card-body class="statistics-body">
              <b-row>
                <b-col xl="3" sm="3">
                  <b-media no-body v-b-tooltip.hover.top="$t('Pages.Channel.Title.count_channel_tooltip')">
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-primary">
                        <feather-icon size="24" icon="GlobeIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0" v-if="statistics.totalChannel"> {{
                        statistics.totalChannel }}</h4>
                      <h4 class="font-weight-bolder mb-0" v-else>0</h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t("Pages.Channel.Title.count_channel") }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col xl="3" sm="3">
                  <b-media no-body v-b-tooltip.hover.top="$t('Pages.Accounting.Title.cal_all_income_tooltip')">
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-info">
                        <feather-icon size="24" icon="CreditCardIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0" v-if="statistics.totalRevenue"> {{
                        statistics.totalRevenue }}</h4>
                      <h4 class="font-weight-bolder mb-0" v-else>0</h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t("Pages.Accounting.Title.cal_all_income") }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col xl="3" sm="3">
                  <b-media no-body v-b-tooltip.hover.top="$t('Pages.Accounting.Title.cal_settled_tooltip')">
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-success">
                        <feather-icon size="24" icon="DollarSignIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0" v-if="statistics.paidRevenue"> {{
                        statistics.paidRevenue }}</h4>
                      <h4 class="font-weight-bolder mb-0" v-else>0</h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t("Pages.Accounting.Title.cal_settled") }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>

                <b-col xl="3" sm="3">
                  <b-media no-body
                    v-b-tooltip.hover.top="$t('Pages.Accounting.Title.cal_unsettled_tooltip')">
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-danger">
                        <feather-icon size="24" icon="DollarSignIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0" v-if="statistics.notpaidRevenue"> {{
                        statistics.notpaidRevenue }}</h4>
                      <h4 class="font-weight-bolder mb-0" v-else>0</h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t("Pages.Accounting.Title.cal_unsettled") }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>

              </b-row>
            </b-card-body>
          </b-card>
        </b-overlay>

      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-card-body>
            <b-row class="text-center">
              <b-col cols="4">
                <b-form-group>
                  <label for="channel">{{ $t('Pages.Accounting.Label.Track') }}</label>
                  <b-form-input
                  v-model="track"
                  :placeholder="$t('Pages.Accounting.Label.Search')"
                  @keyup="applyFilter"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group>
                  <label for="channel">{{ $t('Pages.Accounting.Label.Channel') }}</label>
                  <v-select
                  v-model="channel"
                  :options="ChannelList"
                  :dir="$i18n.locale == 'fa' ? 'rtl' : 'ltr'"
                  label="nickname"
                  :reduce="channel => channel.channel_id"
                  :placeholder="$t('Pages.Accounting.Label.select_a_ch')"
                  class="w-100 select-channel"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group>
                  <label for="month">{{ $t('Pages.Accounting.Label.Month') }}
                
                  </label>
                  <v-select
                  :dir="$i18n.locale== 'fa' ? 'rtl' : 'ltr'"
                  v-model="month"
                  :options="monthList"
                  label="name"
                  :reduce="month => month.value"
                  :placeholder="$t('Pages.Accounting.Label.select_month')"
                  class="w-100 select-channel"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col lg="12" sm="12">
        <b-card v-if="tableData" no-body class="card-company-table">
          <b-overlay :show="!showTableData" spinner-variant="primary" variant="transparent" spinner-type="grow"
            spinner-small rounded="sm">
            <b-table :items="filtersTableData ? filtersTableData : tableData" responsive :fields="fields" show-empty
              :empty-text="$t('Pages.Accounting.Table.empty_payment')" class="mb-0">
              <!-- company -->
              <template #cell(name)="data">
                <b-link :to="{
                  name: 'studioDashboard',
                  params: { channel_id: data.item.channel_id },
                }">
                  <div class="d-flex align-items-center">
                    <b-avatar rounded size="32" variant="light-company">
                      <b-img class="img-fluid" :src="data.item.channel_avatar" alt="avatar img" /></b-avatar>
                    <div class="ml-1">
                      <div class="font-weight-bolder">
                        {{ data.item.channel_name }}
                      </div>
                    </div>
                  </div>
                </b-link>
              </template>

              <template #cell(revenue)="data">
                <div class="d-flex flex-column">
                  <span class="font-weight-bolder mb-25">${{ data.item.revenue }}</span>
                </div>
              </template>

              <!-- views -->
              <template #cell(revenue_type)="data">
                  <div class="d-flex flex-column">
                    <span class="font-weight-bolder mb-25">{{
                      data.item.revenue_type
                    }}</span>
                  </div>
              </template>

              <!-- revenue -->
              <template #cell(revenue_status)="data">
                  <div class="d-flex flex-column">
                    <span class="font-weight-bolder mr-1" v-if="data.item.revenue_status.code == 'paid'">
                      <b-badge pill variant="light-success">
                        {{ data.item.revenue_status.title }}
                      </b-badge>
                    </span>
                    <span class="font-weight-bolder mr-1" v-else>
                      <b-badge pill variant="light-danger">
                        {{ data.item.revenue_status.title }}
                      </b-badge>
                    </span>
                  </div>
              </template>

              <!-- watchtime -->
              <template #cell(date)="data">
                  <div class="d-flex flex-column">
                    <span class="font-weight-bolder mr-1">
                      <b-badge pill variant="light-success">
                        {{ data.item.date }}
                      </b-badge>
                    </span>
                  </div>
              </template>
              <template #cell(actions)="data">
            
                <div class="d-flex align-items-center w-100 p-0">
                  <b-button
                  size="sm"
                  variant="info"
                  @click="getInvoice(data.item.pdf_url)"
                  >
                    <feather-icon icon="DownloadIcon" size="16"/>
                    {{ $t('Pages.Accounting.Button.download_report') }}
                  </b-button>
                  <b-link v-if="data.item.revenue_status.code == 'data-breach'" href="https://master.tube/verification/" target="_blank">
                      <feather-icon
                      icon="AlertTriangleIcon"
                      size="16"
                      class="mx-1 cursor-pointer"
                      v-b-tooltip.hover.top=" $t('Pages.Accounting.Title.info_incomplet')"
                    />
                  </b-link>
                </div>
          
              </template>
             
            </b-table>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col cols="12" v-if="ChannelList.length > 3">
        <b-pagination-nav :link-gen="linkGen" :number-of-pages="pages" use-router class="mb-0" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BAvatar,
  BTable,
  BImg,
  BLink,
  BButton,
  BAlert,
  BBadge,
  BTooltip,
  BPaginationNav,
  BFormGroup,
  BFormInput,
  BOverlay,
  BInputGroup,
  BInputGroupAppend,
  BTabs,
  BTab,
  BMedia,
  BMediaAside,
  BMediaBody,
  BCardBody,
  BCardHeader,
  BCardSubTitle,
  BCardTitle,
  BProgress,
  BModal,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";


export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BAvatar,
    BTable,
    BImg,
    BLink,
    BButton,
    BAlert,
    BBadge,
    BTooltip,
    BPaginationNav,
    BFormGroup,
    BFormInput,
    BOverlay,
    BInputGroup,
    BInputGroupAppend,
    BTabs,
    BTab,
    BMedia,
    BMediaAside,
    BMediaBody,
    BCardBody,
    BCardHeader,
    BCardSubTitle,
    BCardTitle,
    BProgress,
    BModal,
    VBTooltip,
    vSelect
  },
  data() {
    return {
      tableData: [],
      fields: [
        { key: "name", label: this.$t("Pages.Accounting.Table.ch_name") },
        { key: "revenue", label: this.$t("Pages.Accounting.Table.Revenue") },
        { key: "revenue_type", label: this.$t("Pages.Accounting.Table.revenue_type") },
        { key: "revenue_status", label: this.$t("Pages.Accounting.Table.revenue_status") },
        { key: "date", label: this.$t("Pages.Accounting.Table.Date") },
        { key: "actions", label: this.$t("Pages.Accounting.Table.Operation") },
      ],
      ChannelList: [],
      monthList: [
        {name: 'January', value: 1},
        {name: 'February', value: 2},
        {name: 'March', value: 3},
        {name: 'April', value: 4},
        {name: 'May', value: 5},
        {name: 'June', value: 6},
        {name: 'July', value: 7},
        {name: 'August', value: 8},
        {name: 'September', value: 9},
        {name: 'October', value: 10},
        {name: 'November', value: 11},
        {name: 'December', value: 12},
      ],
      loadingTable: true,
      showTableData: false,
      filter: '',
      filtersTableData: false,
      statistics: {
        totalChannel: 0,
        totalRevenue: 0,
        paidRevenue: 0,
        notpaidRevenue: 0
      },
      count_channels: 1,
      channel: '',
      month: '',
      track: '',
      has_data_breach: false,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  created() {
    this.getRenevueList()
    this.getUserChannels()
  },
  watch: {
    'filter'(newVal, oldVal){
      if (!newVal){
        this.filtersTableData = false
        return
      }
      this.filtersTableData = []
      this.tableData.map(item => {
        if (item.channel_name.search(newVal) != -1){
          this.filtersTableData.push(item)
        }
      })
    },
    'channel'(newVal){
      if (newVal === null) this.channel = ''
      this.applyFilter()
    },
    'month'(newVal){
      if (newVal === null) this.month = ''
      this.applyFilter()
    },
  },
  methods: {
    getRenevueList(){
      this.$http.get(`/api/accounting/revenue-list?channel=${this.channel}&month=${this.month}&track=${this.track}`)
      .then(({data}) => {
        if (data.status){
          this.tableData = data.data
          this.tableData.map(item=>{
            if(item.revenue_status.code == 'data-breach'){
              this.has_data_breach = true
            }
          })
          this.statistics.totalChannel = data.totalChannel
          this.statistics.totalRevenue = data.totalRevenue
          this.statistics.paidRevenue = data.paidRevenue
          this.statistics.notpaidRevenue = data.notpaidRevenue
        }
        this.showTableData = true
        this.loadingTable = false
      })
    },
    getUserChannels(){
      this.$http.get('/api/accounting/channel-list')
      .then(({data}) => {
        if (data.status){
          this.ChannelList = data.channels
        }
      })
    },
    applyFilter(){
      this.statistics = {
        totalChannel: 0,
        totalRevenue: 0,
        paidRevenue: 0,
        notpaidRevenue: 0
      }
      this.tableData = []
      this.loadingTable = true
      this.showTableData = false
      this.getRenevueList()
    },
    getInvoice(pdf_url){
      window.open(`${pdf_url}`, 'blank')
    }
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style>
.modal-backdrop {
  opacity: 0.7 !important;
}

[dir] .spinner-border {
  border: 0px !important;
}
</style>

<style scoped>
.select-channel {
  height: 2.714rem;
}
.select-channel.v-select .vs__dropdown-toggle {
  height: 2.714rem !important;
}
</style>